import { RouteRecordRaw } from "vue-router";

const sidebarName = "SidebarStations";

export const stationsRoutes: RouteRecordRaw[] = [
  {
    path: "/stations",
    component: () => import("@/views/common/Home.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/stations/stations",
    component: () => import("@/views/admin/Stations.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/stations/stations/:stationid",
    component: () => import("@/views/admin/StationDetail.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/stations/alerts",
    component: () => import("@/views/common/AlertStation.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/stations/samplingPoints",
    component: () => import("@/views/stations/SamplingPoints.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/stations/graph",
    component: () => import("@/views/stations/Graph.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/stations/generatedAlerts",
    component: () => import("@/views/common/GeneratedAlerts.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/stations/report",
    component: () => import("@/views/common/ReportStation.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
];
