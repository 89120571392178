const resource = {
  "menu": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
    "stations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estaciones"])},
    "containers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenedores"])},
    "samplingPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntos de monitorización"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de usuarios"])},
    "parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de parámetros"])},
    "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de alertas"])},
    "opendata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Data"])},
    "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carga manual de imágenes"])},
    "saica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAICA"])},
    "saih": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAIH"])},
    "aemet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AEMET"])},
    "graph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graficar"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soporte"])},
    "satellite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satelital"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informes"])},
    "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validación de datos"])},
    "validateData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validar datos"])},
    "uploaderData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargar datos"])}
  },
  "title": {
    "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vista previa"])},
    "dialogUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmación de parámetros"])},
    "dataValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selección de datos a importar"])},
    "sendManualCapture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar captura manual"])},
    "dataUploadInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información subida de datos"])}
  },
  "subtitle": {
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumen"])},
    "validationResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado de la comparativa"])}
  },
  "label": {
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de usuario"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
    "subtype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtipo"])},
    "locality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localidad"])},
    "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provincia"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
    "waterFlow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caudal (L/s)"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
    "containerList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de contenedores"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atención"])},
    "operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
    "mainInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información general"])},
    "container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenedor"])},
    "containerEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar contenedor"])},
    "containerNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo contenedor"])},
    "identifying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificador"])},
    "dataEnvironment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos ambientales"])},
    "dataOperational": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos operacionales"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usuario"])},
    "userNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo usuario"])},
    "userEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar usuario"])},
    "userList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de usuarios"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
    "dataUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos de usuario"])},
    "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar contraseña"])},
    "titlePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar contraseña"])},
    "repeatPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir contraseña"])},
    "rol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de usuario"])},
    "assignRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asignar rol"])},
    "population": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Población"])},
    "populationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de población"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visible"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hasta"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posición"])},
    "pouringType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de vertido"])},
    "registry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro"])},
    "samplingPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punto de monitorización"])},
    "samplingPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntos de monitorización"])},
    "samplingPointList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de puntos de monitorización"])},
    "samplingPointNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo punto de monitorización"])},
    "samplingPointEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar punto de monitorización"])},
    "samplingPointInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información del punto de monitorización"])},
    "loginWelcomeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenido a <strong>Monicas</strong>"])},
    "loginWelcomeBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La aplicación para la monitorización de calidad de las aguas superficiales"])},
    "copyright": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Copyright ® ", _interpolate(_named("year")), " ", _interpolate(_named("logo")), " Todos los derechos reservados"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])},
    "codeFTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código FTP"])},
    "typeDischarge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
    "receivingMedium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medio receptor"])},
    "lightingSource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuente de iluminación"])},
    "waterMovement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movimiento de agua"])},
    "containerSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamaño recipiente"])},
    "angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ángulo"])},
    "utmX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordenada X"])},
    "utmY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordenada Y"])},
    "timeZone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zona horaria"])},
    "station": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estación"])},
    "stationsList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de estaciones"])},
    "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración"])},
    "identifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificador"])},
    "wakeTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hora de despertar"])},
    "sleepTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hora de dormir"])},
    "massCapture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["captura masiva"])},
    "functionMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modo"])},
    "sendInterval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervalo de datos"])},
    "bluetooth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bluetooth"])},
    "stationEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar estación"])},
    "stationNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva estación"])},
    "imageViewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visor de imágenes"])},
    "originalImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imagen original"])},
    "parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetros"])},
    "parameterNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo parámetro"])},
    "parameterAssign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurar parámetros"])},
    "parameterAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los parámetros"])},
    "units": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unidades"])},
    "abbreviation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abreviatura"])},
    "instrumentationRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rango de instrumentación"])},
    "physicalRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rango físico del parámetro"])},
    "range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rango"])},
    "precision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisión"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unidad"])},
    "availableParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetros disponibles"])},
    "operational": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operacional"])},
    "environmental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ambiental"])},
    "quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calidad del agua"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
    "meteorology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos meteorológicos"])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Máximo"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mínimo"])},
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media"])},
    "apikey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Api key"])},
    "alertCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear alerta"])},
    "alertNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva alerta"])},
    "alertEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar alerta"])},
    "alertList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de alertas"])},
    "monitorizationPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punto de monitorización"])},
    "parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetro"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor"])},
    "valueIA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor IA"])},
    "oper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oper"])},
    "operator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operador"])},
    "compositeOperator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["And/Or"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo"])},
    "sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
    "interface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interfaz"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
    "rangeDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rango de fechas"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
    "river": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Río"])},
    "saicaCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva SAICA"])},
    "saicaEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar SAICA"])},
    "municipality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Municipio"])},
    "confederation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confederación"])},
    "coordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordenadas"])},
    "saica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAICA"])},
    "environment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medio"])},
    "opendataAssign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de estaciones OpenData"])},
    "opendata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Data"])},
    "opendataAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las estaciones OpenData"])},
    "reportParameterList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informe parámetros"])},
    "lastWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última semana"])},
    "last3months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimos 3 meses"])},
    "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Último mes"])},
    "aemetCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva AEMET"])},
    "aemetEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar AEMET"])},
    "latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latitud"])},
    "longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longitud"])},
    "pluviometry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pluviometría"])},
    "pressure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presión"])},
    "roomTemperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperatura ambiente"])},
    "wind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viento"])},
    "stations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estaciones"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas"])},
    "field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo"])},
    "office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oficina"])},
    "uploadImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subir imagen"])},
    "prediction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predicción"])},
    "magnitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magnitud"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado"])},
    "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validar"])},
    "validateData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validación de datos"])},
    "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desde"])},
    "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasta"])},
    "showIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar en"])},
    "graph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gráfica"])},
    "table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabla"])},
    "anyStation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cualquier estación"])},
    "uploadedData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos subidos"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
    "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origen"])},
    "noStation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin estación"])},
    "estimation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimación"])},
    "difference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desviación media"])},
    "valueMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor máximo"])},
    "valueMin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor mínimo"])},
    "percentile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentil"])},
    "sur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SuR 20%"])},
    "andOr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AND/OR"])},
    "noProcess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin procesar"])},
    "absolute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absolutas"])},
    "relative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relativas"])},
    "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["manual"])},
    "trend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tendencia"])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referencia"])},
    "ranges": {
      "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[0, 10]"])},
      "CDOM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[0, > 10]"])},
      "TSS_Nechad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[0, >150]"])},
      "AVW_vis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[400-700] azul:[400-4900] verde:[500-599] rojo[600-700]"])},
      "Chla": {
        "Toming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[0 y >200]"])}
      }
    },
    "measurement_units": {
      "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["m"])},
      "CDOM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mg/g"])},
      "TSS_Nechad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mg/g"])},
      "AVW_vis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nm"])},
      "Chla": {
        "Toming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mg/m3"])}
      }
    },
    "raw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruto"])},
    "clean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpio"])}
  },
  "text": {
    "parameterTrue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este punto de monitorización tiene todos los parámetros."])},
    "parameterFalse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este punto de monitorización no tiene todos los parámetros."])},
    "opendataTrue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este punto de monitorización tiene todas las estaciones OpenData"])},
    "opendataFalse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este punto de monitorización no tiene todas las estaciones OpenData"])},
    "noDataStation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta estación no ha enviado datos."])},
    "uploadImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Captura manual de una imagen en un río."])},
    "uploadTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La imagen tiene que ser en jpg/png con un tamaño superior a 2592x1944"])},
    "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alertas en los puntos de monitorización."])},
    "alertStation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alertas de las estaciones."])},
    "generatedAlerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aviso de alertas."])}
  },
  "placeholder": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver"])},
    "typeParameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["operacional,ambiental, calidad de agua..."])}
  },
  "status": {
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activo"])},
    "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no activo"])}
  },
  "btn": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión"])},
    "accountConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración de la cuenta"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptar"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurar"])},
    "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
    "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar"])},
    "deactivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desactivar"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anterior"])},
    "changeViewMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar vista"])},
    "deactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DESACTIVADA"])},
    "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar"])},
    "showMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver más"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
    "btn24h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 horas"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
    "deleteAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar todas"])},
    "selectFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar archivo"])},
    "selectDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar fecha"])},
    "uploadToServer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesar"])},
    "visualize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizar"])},
    "uploadData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importar"])},
    "automaticPreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vista previa automática"])},
    "manualPreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vista previa manual"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
    "captureImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capturar imagen"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargar"])},
    "satelital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos satelitales"])},
    "downloadCSV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar CSV"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar"])},
    "cleanningAndUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpiar y subir"])},
    "sendCleanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subir limpieza"])},
    "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vista previa"])}
  },
  "radio": {
    "cleaningprocess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceso de limpieza "])},
    "automatic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automático"])},
    "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])}
  },
  "error": {
    "any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha ocurrido un error."])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo obligatorio."])},
    "min": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El campo debe tener al menos ", _interpolate(_named("min")), " caracteres."])},
    "pageNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página no encontrada"])},
    "ACCESS_DENIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso denegado."])},
    "LOGIN_FAILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario o contraseña incorrecto."])},
    "FORM_VALIDATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hay campos no válidos."])},
    "FORM_DUPLICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos duplicados."])},
    "SESION_EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La sesión ha caducado."])},
    "PASS_DONTMATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las contraseñas no coinciden."])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos duplicados."])},
    "pass_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige una contraseña más larga"])},
    "NO_ALERTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay ninguna alerta guardada"])},
    "no_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La alerta no tiene nombre"])},
    "no_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La alerta no tiene descripción"])},
    "dateFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El formato de la fecha no es corecto"])},
    "loadData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha podido cargar los datos"])},
    "notFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona un archivo primero"])},
    "sizeFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivo demasiado pesado"])},
    "notParameter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El parámetro \"", _interpolate(_named("name")), "\" no se reconoce."])},
    "notRangeDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona primero un rango de fechas"])},
    "notInside": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El rango de fechas del archivo no corresponde al seleccionado"])},
    "wrongDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compruebe el rango de fechas."])},
    "dateRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El rango de fechas no debe ser superior a 4 días"])},
    "notSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El parámetro seleccionado no se encuentra en el archivo."])},
    "invalidLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La ubicación del dispositivo no coincide con la del punto de monitorización."])}
  },
  "success": {
    "load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha cargado con éxito"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operación realizada con éxito."])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesión iniciada."])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesión finalizada."])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha guardado con éxito"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha borrado con éxito"])}
  },
  "warning": {
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicado"])},
    "notData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay datos"])},
    "operSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay selección de operadores"])},
    "notSelectedParameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona primero un parámetro"])},
    "stationOccupied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La estación ya tiene un punto de monitorización asignado"])},
    "notImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay imágenes"])},
    "notNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay avisos"])}
  },
  "confirm": {
    "activateContainer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El contenedor <b>", _interpolate(_named("name")), "</b> será activado<br/>¿Desea continuar?"])},
    "deactivateContainer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El contenedor <b>", _interpolate(_named("name")), "</b> será desactivado<br/>¿Desea continuar?"])},
    "activateUser": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El usuario <b>", _interpolate(_named("name")), "</b> será activado<br/>¿Desea continuar?"])},
    "deactivateUser": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El usuario <b>", _interpolate(_named("name")), "</b> será desactivado<br/>¿Desea continuar?"])},
    "activateStation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La estación <b>", _interpolate(_named("name")), "</b> será activado<br/>¿Desea continuar?"])},
    "deactivateStation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La estación <b>", _interpolate(_named("name")), "</b> será desactivado<br/>¿Desea continuar?"])},
    "activateSamplingPoint": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El punto de monitorización <b>", _interpolate(_named("name")), "</b> será activado<br/>¿Desea continuar?"])},
    "deactivateSamplingPoint": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El punto de monitorización <b>", _interpolate(_named("name")), "</b> será desactivado<br/>¿Desea continuar?"])},
    "deleteAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta alerta y sus notificaciones serán borradas. ¿Desea continuar?"])},
    "activateAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La alerta <b>", _interpolate(_named("name")), "</b> será activada<br/>¿Desea continuar?"])},
    "deactivateAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La alerta <b>", _interpolate(_named("name")), "</b> será desactivada<br/>¿Desea continuar?"])},
    "activateParameter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El parámetro <b>", _interpolate(_named("name")), "</b> será activado<br/>¿Desea continuar?"])},
    "deactivateParameter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El parámetro <b>", _interpolate(_named("name")), "</b> será desactivado<br/>¿Desea continuar?"])},
    "deleteGeneratedAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este aviso será borrado<br/>¿Desea continuar?"])}
  },
  "info": {
    "infoUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se van a importar los siguientes parámetros encontrados en el archivo."])},
    "locationRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe permitir utilizar la ubicación del dispositivo"])},
    "cameraRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe permitir utilizar la camara del dispositivo"])},
    "notificationNumber": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("number")), " alertas en los últimos ", _interpolate(_named("minutes")), " minutos"])},
    "samplingPointsNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha encontrado ningún punto de monitorización."])},
    "stationsNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha encontrado ningúna estación."])},
    "calculateTrend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cálculo de la tendencia se realiza haciendo una media de los últimos valores y comparándola con el último valor"])},
    "upwardTrend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Si el último valor es superior a la media la tendencia está subiendo 🡩"])},
    "downwardTrend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Si el último valor es inferior a la media la tendencia está bajando 🡫"])},
    "flatTrend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Si el último valor es igual a la media la tendencia se mantiene 🡪"])},
    "param": {
      "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hace referencia a la profundidad del disco de Secchi, un parámetro asociado a la claridad del agua, ya que cuanto mayor sea su valor, más clara es. Valores altos de claridad, pueden relacionarse con valores bajos de TU."])},
      "CDOM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hace referencia a la concentración de materia orgánica con color disuelta en el agua. Cuanto mayor sea, el agua presentará un color más oscuro."])},
      "TSS_Nechad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta variable está vinculada con el grado de turbidez del agua; cuanto más alta sea la concentración de sólidos en suspensión, mayor será la turbidez."])},
      "AVW_vis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hace referencia al color predominante del agua. En concreto lo que se mide es la longitud de onda predominante (nm), dentro del rango del visible. El agua fluvial se puede clasificar en 4 colores fundamentales (azul, verde, amarillo y marrón oscuro), cuyas longitudes de onda centrales son 468, 565, 572.5 y 605 nm, respectivamente."])},
      "AVW_visnir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longitud de onda predominante (nm) del agua, considerando el rango del visible (VIS) y del infrarrojo cercano (NIR). Es un parámetro complementario a AVW_vis, ya que da una idea de la influencia del NIR (que para el agua clara y sin alteraciones, es prácticamente nula)."])},
      "Chla": {
        "Toming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es indicativo de la concentración de clorofila α en el agua del río (grado de eutrofización del agua, ya que es una variable asociada a la cantidad de biomasa algal)."])}
      },
      "Chl_a": {
        "Se2WaQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indica la concentración de clorofila a en el agua del río (grado de eutrofización del agua). Se2WaQ hace referencia al algoritmo empleado."])}
      }
    },
    "trend": {
      "DOWNWARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🡫"])},
      "UPWARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🡩"])},
      "FLAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🡪"])}
    }
  },
  "app": {
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio de apoyo a la toma de decisiones"])},
    "stations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio de Gestión de estaciones"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herramienta Administración"])}
  },
  "magnitude": {
    "GPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GPS"])},
    "voltage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batería"])},
    "time_batery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo estimado de carga"])},
    "percentage_batery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porcentaje batería"])},
    "stream": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corriente media consumida"])},
    "humedity_int": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Humedad interior"])},
    "temp_int": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperatura interior"])},
    "available_SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espacio SD"])},
    "optical_operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funcionamiento óptico"])},
    "rssi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RSSI"])},
    "log_present": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["log_present"])},
    "state_SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado SD"])},
    "capture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Captura"])},
    "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardado en la SD"])},
    "luminosity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luminosidad"])},
    "temp_ext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperatura exterior"])},
    "temp_water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperatura del agua"])},
    "atmospheric_pressure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presión atmosférica"])},
    "humidity_ext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Humedad exterior"])},
    "distance_water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia del agua"])},
    "angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ángulo"])},
    "conductivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conductividad"])},
    "turbidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turbidez"])},
    "sst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sólidos en suspensión"])},
    "ammonium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amonio:"])},
    "550nm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiespectral (550nm)"])},
    "410nm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiespectral (410nm)"])},
    "440nm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiespectral (440nm)"])},
    "470nm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiespectral (470nm)"])},
    "510nm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiespectral (510nm)"])},
    "583nm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiespectral (583nm)"])},
    "620nm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiespectral (620nm)"])},
    "670nm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiespectral (670nm)"])},
    "CLEAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiespectral (CLEAR)"])},
    "NIR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espectrometro (Multiespectral NIR)"])},
    "luminosity_sky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luminosidad del cielo"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nivel"])},
    "level_station": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nivel estación"])},
    "iccid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iccid"])}
  },
  "format": {
    "dateTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DD/MM/YYYY HH:mm:ss"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DD/MM/YYYY"])}
  },
  "parameterType": {
    "station": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estación"])},
    "samplingpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punto de monitorización"])},
    "operational": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operacional"])},
    "environmental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ambiental"])},
    "quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calidad"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
    "meteorology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos meteorológicos"])},
    "data_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos de imagen"])}
  },
  "tooltip": {
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deshacer filtros"])}
  },
  "enum": {
    "fields": {
      "ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos"])},
      "STATION_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de estación"])},
      "STATION_STATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificador de estación"])},
      "SAMPLINGPOINT_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre punto de monitorización"])},
      "SAMPLINGPOINT_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código punto de monitorización"])},
      "SAMPLINGPOINT_RIVER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Río punto de monitorización"])},
      "ALERT_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la alerta"])}
    },
    "params": {
      "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claridad"])},
      "CDOM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materia orgánica disuelta"])},
      "TSS_Nechad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sólidos en suspensión"])},
      "AVW_vis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color del agua"])},
      "AVW_visnir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ColorIR"])},
      "Chla": {
        "Toming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clorofila α"])}
      },
      "Chl_a": {
        "Se2WaQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clorofila 1"])}
      }
    },
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deshacer filtros"])},
    "graph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graficar"])}
  }
}
export default resource