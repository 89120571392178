import { RouteRecordRaw } from "vue-router";

const sidebarName = "SidebarSupport";

export const supportRoutes: RouteRecordRaw[] = [
  {
    path: "/support",
    component: () => import("@/views/common/Home.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/support/samplingPoints",
    component: () => import("@/views/support/SamplingPoints.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/support/ia",
    component: () => import("@/views/support/upload-image.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/support/samplingPoints/:containerid",
    component: () => import("@/views/support/ContainerDetail.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/support/alerts",
    component: () => import("@/views/support/Alerts.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/support/graph",
    component: () => import("@/views/support/Graph.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/support/generatedAlerts",
    component: () => import("@/views/common/GeneratedAlerts.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/support/report",
    component: () => import("@/views/common/Report.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/support/upload",
    component: () => import("@/views/support/UploadData.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/support/validation",
    component: () => import("@/views/support/Validate_2.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    name: "support-satelital",
    path: "/support/satelite",
    component: () => import("@/views/support/Satelital.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
];
