
import securityService from "@/service/security.service";
import { RootState } from "@/types/state.type";
import { computed, defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  props: {
    collapsable: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const store = useStore<RootState>();
    const router = useRouter();
    const app = computed(() => securityService.getApp());
    const notificationCss = ref("");
    const firstTime = true;

    return {
      newAlert: false,
      router,
      store,
      app,
      notificationCss,
      firstTime,
    };
  },
  mounted() {
    if (document.body.clientWidth < 1500) {
      this.collapseChage();
    }
    this.firstTime = false;
  },
  watch: {
    notifications: {
      immediate: true,
      handler(newVal) {
        if (this.firstTime && !newVal) {
          return;
        }
        this.notificationCss = "shake";
        setTimeout(() => {
          this.notificationCss = "";
        }, 2000);
      },
    },
  },
  computed: {
    collapse(): boolean {
      return this.store.state.ui.sidebarOpen;
    },
    username(): string {
      return this.store.state.auth.user?.email || "";
    },
    notifications(): number | null {
      return this.store.state.ui.notifications;
    },
  },
  methods: {
    handleCommand(command: string) {
      if (command == "loginout") {
        // - do logout
        this.store.dispatch("auth/logout");
        this.router.push("/");
      }
      // else if (command == "user") {
      //   this.router.push("/user");
      // }
    },
    collapseChage(): void {
      this.store.commit("ui/sidebarToggle");
    },
    goToAlerts() {
      if (this.$route.path.includes("admin")) {
        this.$router.push("/admin/generatedAlerts");
      } else if (this.$route.path.includes("stations")) {
        this.$router.push("/stations/generatedAlerts");
      } else {
        this.$router.push("/support/generatedAlerts");
      }
      this.newAlert = false;
    },
  },
});
