<template>
  <div class="footer">
    <div class="logo"><img src="/img/image_ministerio.png" alt="ministerio" /></div>
    <div class="logo"><img src="/img/Logo-CDTIs.jpg" alt="cdti" /></div>
    <div class="logo"><img src="/img/logo-feder.png" alt="feder" /></div>
  </div>
</template>
<script lang="ts"></script>
<style lang="scss" scoped>
@import "src/assets/styles/variables.scss";
@import "node_modules/element-plus/theme-chalk/src/mixins/mixins.scss";

.footer {
  width: 100%;
  display: flex;
  align: center;
  justify-content: center;
}
.logo {
  padding: 2px;
}
.logo img {
  max-height: 60px;
}
</style>
