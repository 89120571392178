import { createStore } from "vuex";
import { authStore } from "./auth.store";
import { uiStore } from "./ui.store";

export default createStore({
  modules: {
    auth: authStore,
    ui: uiStore,
  },
});
