import { RootState } from "./../types/state.type";
import { AxiosError, AxiosRequestConfig } from "axios";
import { ElLoading, ElMessage } from "element-plus";
import { LoadingInstance } from "element-plus/es/components/loading/src/loading";
import { Store } from "vuex";
import axiosInstance from "./../service/api";
import TokenService from "./../service/token.service";
import router from "./../router";
import { AppError } from "./../types/app.type";
import { UserAccess } from "./../types/auth.type";

/**
 * AxiosRequestConfigRetry
 */
interface AxiosRequestConfigRetry extends AxiosRequestConfig {
  _retry: boolean; // - retries in case of 401
}

/**
 * setup axios interceptor
 * @param store
 */
export const setupAxiosInterceptor = (store: Store<RootState>): void => {
  let loadingInstance: LoadingInstance | null = null;

  axiosInstance.interceptors.request.use(
    (config) => {
      // - add accesstoken in Authorization header
      const token = TokenService.getLocalAccessToken();
      if (token) {
        if (!config.headers) {
          config.headers = {};
        }
        config.headers["x-access-token"] = token; // for Node.js Express back-end
      }

      // - loading - open
      loadingInstance = ElLoading.service({
        lock: true,
        text: "Loading",
      });
      return config;
    },
    (err: AxiosError) => {
      // - loading - close
      if (loadingInstance) {
        loadingInstance.close();
      }
      ElMessage.error(err.message);
      return Promise.reject(err);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      // - loading - close
      if (loadingInstance) {
        loadingInstance.close();
      }
      return res;
    },
    async (err: AxiosError) => {
      // - loading - close
      if (loadingInstance) {
        loadingInstance.close();
      }

      // - check if it is necessary to use the refreshtoken
      const originalConfig = err.config as AxiosRequestConfigRetry;
      if (originalConfig.url !== "/auth/login" && err.response) {
        // - access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;

          try {
            const rs = await axiosInstance.post<UserAccess>("/auth/refreshtoken", {
              refreshToken: TokenService.getLocalRefreshToken(),
            });

            store.dispatch("auth/refreshToken", rs.data);
            TokenService.setUser(rs.data);

            return axiosInstance(originalConfig);
          } catch (_error) {
            // - logout
            store.dispatch("auth/logout", { revoke: false });
            router.push("/");

            return Promise.reject(AppError.SESION_EXPIRED);
          }
        }
      }

      return Promise.reject(err);
    }
  );
};
