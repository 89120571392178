import moment from "moment-timezone";
import { responseHandlerDefault } from "./../utils/handler.util";
import axios from "./api";
import { AlertNotification } from "./../types/notificationAlert.type";
import { Pagination } from "./../types/pagination.type";
import { Filter } from "./../types/filter.type";

export const getNotifications = (
  page = 1,
  size = 10,
  type = "all",
  filter?: Filter
): Promise<Pagination<AlertNotification>> => {
  const params: { page?: number; size?: number; type?: string; filter?: Filter } = {};
  if (page) {
    params.page = page;
  }
  if (size) {
    params.size = size;
  }
  if (type) {
    params.type = type;
  }
  if (filter) {
    params.filter = filter;
  }
  return axios.get(`/notifications/`, { params }).then(responseHandlerDefault);
};

export const deleteNotification = (alert: AlertNotification): Promise<number> => {
  return axios.post(`/notifications/remove/${alert.id}`).then(responseHandlerDefault);
};

export const hasNotifications = (lastMinutes: number): Promise<number> => {
  const params: { page?: number; size?: number; type?: string; filter?: Filter } = {};
  params.page = 1;
  params.size = 1;
  params.type = "all";
  params.filter = {
    fields: {
      "alertNotification.dateTime": {
        operator: "between",
        value: [moment().add(lastMinutes, "minutes").toISOString(), moment().toISOString()],
      },
    },
  };
  return axios
    .get<Pagination<AlertNotification>>(`/notifications/`, { params })
    .then((res) => res.data?.totalRows || 0);
};
