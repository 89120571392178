import axios from "axios";

export default axios.create({
  baseURL: process.env.VUE_APP_API_URL
    ? process.env.VUE_APP_API_URL
    : `${location?.protocol || "http"}//${location?.hostname || "localhost"}:3000/api/`,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});
